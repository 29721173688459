import React from 'react';
import { HistoryLocation } from '@reach/router';
import Header from '../Header';
import styles from './Layout.module.css';
import Footer from '../Footer/Footer';

export interface LayoutProps {
  title: string;
  location: HistoryLocation;
}

const Layout: React.FC<LayoutProps> = (props) => {
  const { title, children } = props;

  return (
    <div className={styles.layout}>
      <Header title={title} />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
