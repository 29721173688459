import React from 'react';
import { Link } from 'gatsby';
import { GithubOutlined, TwitterOutlined } from '@ant-design/icons';
import styles from './Header.module.css';
// import Avatar from '../Avatar/Avatar';

const Header = ({ title }) => {
  return (
    <header className={styles.header}>
      <h1 className={styles.title}>
        <Link className={styles.link} to={`/`}>
          {title}
        </Link>
      </h1>
      <div style={{ height: '100%', paddingRight: '20px' }}>
        <iframe
          src="https://github.com/sponsors/DenysVuika/button"
          title="Sponsor DenysVuika"
          height="35"
          width="116"
          style={{ border: 0 }}
        ></iframe>
      </div>
      <a
        href="https://github.com/DenysVuika"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.social}
      >
        <GithubOutlined />
      </a>
      <a
        href="https://twitter.com/DenysVuika"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.social}
      >
        <TwitterOutlined />
      </a>
      {/* <div className={styles.avatar}>
        <Link className={styles.link} to={`/`}>
          <Avatar />
        </Link>
      </div> */}
    </header>
  );
};

export default Header;
