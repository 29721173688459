import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import gatsbyIcon from '../../../content/assets/avatar-512x512.jpg';

export interface SiteMetadataProps {
  title: string;
  pathname?: string;
  description?: string;
  thumbnail?: string;
  keywords?: string[];
}

export interface SiteMetadataData {
  site: {
    siteMetadata: {
      siteUrl: string;
      title: string;
      description: string;
      author: string;
      twitter: string;
    };
  };
}

const SiteMetadata: React.FC<SiteMetadataProps> = (props) => {
  const data: SiteMetadataData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
            twitter
          }
        }
      }
    `
  );

  const siteMetadata = data.site.siteMetadata;
  const description = props.description || siteMetadata.description;
  const canonicalUrl = `${siteMetadata.siteUrl}${props.pathname}`;
  const thumbnailUrl =
    props.thumbnail || `${siteMetadata.siteUrl}${gatsbyIcon}`;

  return (
    <Helmet title={props.title} titleTemplate={`%s | ${siteMetadata.title}`}>
      <html lang="en" />
      <link rel="canonical" href={canonicalUrl} />
      <meta name="docsearch:version" content="2.0" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />

      <meta property="description" content={description} />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={description} />
      {/* <meta property="og:url" content={siteMetadata.siteUrl} /> */}
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en" />
      <meta property="og:site_name" content={props.title} />
      {/* <meta property="og:image" content={`${siteUrl}${gatsbyIcon}`} /> */}
      <meta property="og:image" content={thumbnailUrl} />
      <meta property="og:image:secure_url" content={thumbnailUrl} />
      {/* <meta property="og:image:width" content="512" /> */}
      <meta property="og:image:width" content="1200" />
      {/* <meta property="og:image:height" content="512" /> */}
      <meta property="og:image:height" content="630" />

      <meta name="twitter:title" content={props.title} />
      <meta name="twitter:creator" content={siteMetadata.author} />
      {/* <meta name="twitter:card" content="summary" /> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={siteMetadata.twitter} />
      <meta name="twitter:description" content={description} />

      <meta name="keywords" content={(props.keywords || []).join(`, `)} />
      <script type="text/javascript">
        {`
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "3zpbbffshf");
        `}
      </script>
    </Helmet>
  );
};

SiteMetadata.defaultProps = {
  thumbnail: '',
  pathname: '',
  keywords: [],
};

export default SiteMetadata;
