import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <div className={styles.container}>
      <footer className={styles.footer}>
        © {new Date().getFullYear()} Denys Vuika. Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>.
      </footer>
    </div>
  );
};

export default Footer;
